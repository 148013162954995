import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    h2: "h2",
    ..._provideComponents(),
    ...props.components
  }, {Tag} = _components;
  if (!Tag) _missingMdxReference("Tag", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "size",
      children: _jsx(_components.a, {
        href: "#size",
        children: "Size"
      })
    }), "\n", _jsxs(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `  <Tag size="small" text="S Tag"/>
  <Tag size="medium" text="M Tag"/>
  <Tag size="large" text="L Tag"/>`,
      children: ["  ", _jsx(Tag, {
        size: "small",
        text: "S Tag"
      }), _jsx(Tag, {
        size: "medium",
        text: "M Tag"
      }), _jsx(Tag, {
        size: "large",
        text: "L Tag"
      })]
    }), "\n", _jsx(_components.h2, {
      id: "color",
      children: _jsx(_components.a, {
        href: "#color",
        children: "Color"
      })
    }), "\n", _jsx(_components.code, {
      preview: true,
      className: "language-jsx",
      code: `<Tag
  size="medium"
  text="M Tag"
  color="var(--shade2)"
  hoverColor="var(--blue)"
/>`,
      children: _jsx(Tag, {
        size: "medium",
        text: "M Tag",
        color: "var(--shade2)",
        hoverColor: "var(--blue)"
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
